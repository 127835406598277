import { createRouter, createWebHashHistory } from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

NProgress.configure(
    NProgress.configure({
        easing: "ease", // 动画方式
        speed: 600, // 递增进度条的速度
        showSpinner: false, // 是否显示加载ico
        trickleSpeed: 200, // 自动递增间隔
        minimum: 0.3, // 初始化时的最小百分比
    })
);

const routes = [
    {
        path: "/",
        name: "home",
        meta: {
            isAuth: false,
            keepAlive: true,
        },
        component: () => import("../views/HomeView.vue"),
    },
    {
        path: "/bookList",
        name: "bookList",
        component: () => import("../views/BookListView.vue"),
    },
    {
        path: "/bookDetail",
        name: "bookDetail",
        component: () => import("../views/BookDetailView.vue"),
    },
    {
        path: "/downloadResource",
        name: "downloadResource",
        component: () => import("../views/DownloadResourceView.vue"),
    },
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    // }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    NProgress.start();
    next();
});

router.afterEach(() => {
    NProgress.done();
});

export default router;
