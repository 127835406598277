import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import http from "@/assets/js/request/index";
// import "amfe-flexible";  // 移动端h5兼容
import store from "./store";

const app = createApp(App);

app.provide("$http", http);
app.provide("$host", "http://124.220.164.243");

app.use(router).use(store).mount("#app");
