import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
export default {
  __name: 'App',
  setup(__props) {
    const keepAliveList = ["HomeView"];
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view, null, {
        default: _withCtx(({
          Component
        }) => [(_openBlock(), _createBlock(_KeepAlive, {
          include: keepAliveList
        }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1024))]),
        _: 1
      });
    };
  }
};