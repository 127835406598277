/**
 * axios封装:
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from "axios";
// import QS from "qs";
import { Snackbar } from "@varlet/ui";
import "@varlet/ui/es/snackbar/snackbar.css";
import $router from "@/router/index";
import { getCookie } from "@/assets/js/common/common";

// const ip = "http://127.0.0.1:8000";

const http = ({ method = "POST", url = "", params = {}, loading = false, loadingOptions = {} } = {}) => {
    // axios.defaults.baseURL = '/XXXX'
    // axios.defaults.headers.post['Content-Type'] = 'application/json';

    const csrftoken = getCookie("csrftoken"); // 获取cookie中的csrf值
    axios.defaults.headers.post["X-CSRFToken"] = csrftoken;
    axios.defaults.headers.put["X-CSRFToken"] = csrftoken;
    axios.defaults.headers.delete["X-CSRFToken"] = csrftoken;

    const instance = axios.create({
        baseURL: "/netBookShow/api/",
        timeout: 30000,
    });
    instance.interceptors.request.use(
        async config => {
            return config;
        },
        err => {
            Snackbar({ content: err, duration: 2000, position: "top" });
            console.log("请求失败：" + err);
        }
    );

    instance.interceptors.response.use(
        responseData => {
            return { res: responseData.data, status: responseData.status };
        },
        err => {
            handelErrorMessage(err.response);
            return err.response.data;
        }
    );
    let loadingSnackbar;
    if (loading) {
        loadingSnackbar = Snackbar({
            content: loadingOptions.tip || "加载中",
            duration: loadingOptions.duration || 30000,
            position: loadingOptions.position || "center",
            type: "loading",
            lockScroll: true,
        });
    }
    url = url.substring(0, 1) == "/" ? url.substring(0, url.length) : url;
    let config = {
        // 请求方式，'GET'或者'POST'
        method,
        // 请求地址
        url: url,
    };
    if (method == "GET") {
        // url中的查询参数，GET方法的传参
        config.params = params;
    } else if (method == "POST" || method == "PUT") {
        // 请求体参数，POST方法的传参
        config.data = params;
        config.headers = {
            "Content-Type": "application/json;charset=UTF-8",
        };
    }
    return new Promise(resolve => {
        instance(config).then(({ res, status }) => {
            if (loadingSnackbar) {
                loadingSnackbar.clear();
            }
            res.status = status;
            resolve(res);
        });
    });
};

const handelErrorMessage = ({ data, status }) => {
    if (status == 400) {
        Snackbar({ content: data.msg, duration: 2000, position: "top", type: "error" });
    } else if (status == 403) {
        Snackbar({ content: "当前无权限 / 被禁止访问接口", duration: 2000, position: "top", type: "error" });
        $router.push("/");
        sessionStorage.clear();
        localStorage.clear();
    } else if (status == 404) {
        Snackbar({ content: "访问链接错误，地址不存在", duration: 2000, position: "top", type: "error" });
    } else if (status == 500) {
        Snackbar({ content: "服务器内部发生错误，请联系管理员", duration: 2000, position: "top", type: "error" });
    }
};

export default http;
